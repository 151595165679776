import React, { useState, useEffect } from "react";
import { SleeperDivision } from "./SleeperDivision";
import { useParams } from "react-router-dom";
import SleeperPageNavbar from "./SleeperPageNavbar.js";
import { client } from "../../../services/FantasyNflClient";
import { Container, Row, Col } from "reactstrap";
import Spinner from "../../Common/Spinner";
import { formatDate } from "../../../services/dateUtlity";
import SleeperPageNavLinks from "./SleeperPageNavLinks";

export function SleeperLeague() {
  const [loading, setLoading] = useState(true);
  const [league, setLeague] = useState({});
  let { leagueId } = useParams();

  useEffect(() => {
    client
      .getSleeperLeague(leagueId)
      .then((data) => {
        setLeague(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId]);

  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  function getTeamsByDivision() {
    return groupBy(league.teams, "division");
  }

  return (
    <>
      <SleeperPageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <div className="info">
                <div className="description">
                  <h3 className="info-title">{league.leagueName}</h3>
                  <p className="mb-0">Standings</p>
                  {league.lastStandingsRefresh && (
                    <small>
                      Last Refresh:{" "}
                      {formatDate(
                        league.lastStandingsRefresh,
                        "YYYY-MM-DD HH:mm"
                      )}
                    </small>
                  )}
                </div>
              </div>
              <SleeperPageNavLinks leagueId={leagueId} />
            </Col>
          </Row>
          <Row>
            {getTeamsByDivision().map((division) => (
              <SleeperDivision
                key={division.key}
                leagueId={leagueId}
                divisionName={division.key}
                teams={division.values}
              />
            ))}
          </Row>
        </Container>
      )}
    </>
  );
}
