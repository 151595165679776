import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import { Layout } from "./components/Layout";
import { Team } from "./components/nfl/Team";
import { Admin } from "./components/nfl/Admin";
import { DepthChart } from "./components/nfl/DepthChart";
import { KeeperBreakdown } from "./components/nfl/KeeperBreakdown";
import { RosterBreakdown } from "./components/nfl/RosterBreakdown";
import { DraftOrder } from "./components/nfl/DraftOrder";
import { AvailablePlayers } from "./components/nfl/AvailablePlayers";
import { PointsByYear } from "./components/nfl/PointsByYear";
import { PointsByYearTeam } from "./components/nfl/PointsByYearTeam";
import { PointsByYearTeamWeek } from "./components/nfl/PointsByYearTeamWeek";
import { RookiesByYear } from "./components/nfl/RookiesByYear";
import { NflHome } from "./components/nfl/NflHome";
import { League } from "./components/nfl/League";
import { LeagueHistory } from "./components/nfl/LeagueHistory";

import { SleeperLeague } from "./components/nfl/sleeper/SleeperLeague";
import { SleeperTeam } from "./components/nfl/sleeper/SleeperTeam";
import { SleeperPointsByYear } from "./components/nfl/sleeper/SleeperPointsByYear";
import { SleeperPointsByYearTeam } from "./components/nfl/sleeper/SleeperPointsByYearTeam";
import { SleeperPointsByYearTeamWeek } from "./components/nfl/sleeper/SleeperPointsByYearTeamWeek";

import "./assets/css/bootstrap.min.css";
import "./assets/css/paper-kit.css";
// import "assets/css/paper-kit.min.css";
// import "assets/css/paper-kit.css.map";
import "./assets/demo/demo.css";
import "./assets/demo/react-demo.css";
import "./assets/css/custom.css";
import { LeagueSettings } from "./components/Common/LeagueSettings";

export function App() {
  return (
    <>
      <Route exact path="/" component={NflHome} />
      <Route path="/league/:leagueId" component={LeagueSettings} />?
      <Layout>
        <Route
          exact
          path="/sleeper/league/:leagueId"
          component={SleeperLeague}
        />
        <Route exact path="/league/:leagueId" component={League} />
        <Route
          exact
          path="/sleeper/league/:leagueId/team/:teamId"
          component={SleeperTeam}
        />
        <Route exact path="/league/:leagueId/team/:teamId" component={Team} />
        <Route
          exact
          path="/league/history/:leagueId"
          component={LeagueHistory}
        />
        <Route exact path="/depthchart/:leagueId" component={DepthChart} />
        <Route
          exact
          path="/rosterbreakdown/:leagueId"
          component={RosterBreakdown}
        />
        <Route
          exact
          path="/keeperbreakdown/:leagueId"
          component={KeeperBreakdown}
        />
        <Route exact path="/draftorder/:leagueId" component={DraftOrder} />
        <Route
          exact
          path="/availableplayers/:leagueId"
          component={AvailablePlayers}
        />
        <Route
          exact
          path="/sleeper/history/:leagueId/pointsbyyear/:year"
          component={SleeperPointsByYear}
        />
        <Route
          exact
          path="/history/:leagueId/pointsbyyear/:year"
          component={PointsByYear}
        />
        <Route
          exact
          path="/sleeper/history/:leagueId/pointsbyyear/:year/:teamId"
          component={SleeperPointsByYearTeam}
        />
        <Route
          exact
          path="/history/:leagueId/pointsbyyear/:year/:teamId"
          component={PointsByYearTeam}
        />
        <Route
          exact
          path="/sleeper/history/:leagueId/pointsbyyear/:year/:teamId/:week"
          component={SleeperPointsByYearTeamWeek}
        />
        <Route
          exact
          path="/history/:leagueId/pointsbyyear/:year/:teamId/:week"
          component={PointsByYearTeamWeek}
        />
        <Route
          exact
          path="/rookies/:leagueId/:year"
          component={RookiesByYear}
        />
        <Route exact path="/admin" component={Admin} />
      </Layout>
    </>
  );
}
