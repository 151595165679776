import React from "react";
import { NavItem, NavLink, Nav } from "reactstrap";

function SleeperPageNavLinks({ leagueId }) {
  return (
    <Nav className="justify-content-center">
      <NavItem>
        <NavLink href={"/sleeper/league/" + leagueId}>Standings</NavLink>
      </NavItem>
      {/* <NavItem>
        <NavLink href={"/depthchart/" + leagueId}>Depth Charts</NavLink>
      </NavItem>
      {hasLeagueDrafted() === false && (
        <NavItem>
          <NavLink href={"/draftorder/" + leagueId}>Draft Order</NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink href={"/rosterbreakdown/" + leagueId}>
          Roster Breakdown
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={"/league/history/" + leagueId}>History</NavLink>
      </NavItem> */}

      {/*</NavItem>*/}
      {/*{!settings.availablePlayersHidden && (*/}
      {/*  <NavItem>*/}
      {/*    <NavLink href={"/availableplayers/" + leagueId}>*/}
      {/*      Available Players*/}
      {/*    </NavLink>*/}
      {/*  </NavItem>*/}
      {/*)}*/}

      {/* <NavItem>
        <NavLink href={"/rookies/" + leagueId + "/2024"}>2024 Rookies</NavLink>
      </NavItem> */}
      <NavItem>
        <NavLink href={"/sleeper/history/" + leagueId + "/pointsbyyear/2024"}>
          2024 Team Scores
        </NavLink>
      </NavItem>
      <NavItem></NavItem>
    </Nav>
  );
}

export default SleeperPageNavLinks;
