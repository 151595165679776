import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import SleeperPageNavbar from "./SleeperPageNavbar.js";
import DataTable from "react-data-table-component";

import { Container, Row, Col, Button, Modal } from "reactstrap";
import { client } from "../../../services/FantasyNflClient.js";
import Spinner from "../../Common/Spinner.js";
import { SleeperTeamProfileCard } from "./SleeperTeamProfileCard.js";
import { hasLeagueDrafted } from "../../Common/LeagueSettings.js";
import SleeperPageNavLinks from "./SleeperPageNavLinks.js";

export function SleeperTeam() {
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  let { leagueId, teamId } = useParams();

  useEffect(() => {
    client
      .getSleeperTeam(leagueId, teamId)
      .then((data) => {
        setTeam(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId, teamId]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const selectPlayer = (playerId) => {
    const players = [...team.teamPlayers];
    const selectedPlayer = players
      .filter((u) => u.playerId === playerId)
      .shift();
    if (selectedPlayer) {
      setSelectedPlayer(selectedPlayer);
      toggleModal();
    }
  };

  const changeKeeper = useCallback(
    (playerId) => {
      client
        .teamSetKeeper(teamId, playerId)
        .then((data) => {
          setTeam(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [teamId]
  );

  const columns = useMemo(() => {
    const columns = [];
    columns.push({
      name: "Position",
      selector: "positionSortOrder",
      sortable: true,
      format: (row) => {
        return row.position;
      },
    });

    columns.push(
      ...[
        {
          name: "Position",
          selector: "positionSortOrder",
          sortable: true,
          format: (row) => {
            return row.position;
          },
        },
        { name: "Name", selector: "playerName", sortable: true },
        {
          name: "Team",
          selector: "nflTeam",
          sortable: false,
          format: (row) => {
            return row.nflTeam === "" ? (
              ""
            ) : (
              <img
                alt="..."
                src={require("../../../assets/img/thumbnail/" +
                  row.nflTeam +
                  ".png")}
              ></img>
            );
          },
        },
        {
          name: "Bye",
          selector: "fantasyProsInfo.bye",
          sortable: true,
          format: (row) => {
            return !row.fantasyProsInfo ? "-" : row.fantasyProsInfo.bye;
          },
        },
      ]
    );

    if (!hasLeagueDrafted()) {
      columns.push({
        name: "Keeper",
        selector: "isKeeper",
        sortable: true,
        format: (row) => {
          return (
            <input
              disabled={!team.manualKeepersEnabled ?? true}
              defaultValue=""
              type="checkbox"
              checked={row.isKeeper}
              onChange={() => changeKeeper(row.playerId)}
            />
          );
        },
      });
    }

    columns.push(
      ...[
        { name: "NFL Rank", selector: "nflRank", sortable: true },
        {
          name: "ADP",
          selector: "fantasyProsInfo.avg",
          sortable: true,
          format: (row) => {
            return !row.fantasyProsInfo ? "N/A" : row.fantasyProsInfo.avg;
          },
        },
      ]
    );

    return columns;
  }, [changeKeeper, team.manualKeepersEnabled]);

  return (
    <>
      <SleeperPageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <Container>
          <SleeperTeamProfileCard />
          <SleeperPageNavLinks leagueId={leagueId} />

          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <DataTable
                columns={columns}
                dense={true}
                data={team.teamPlayers}
                striped={true}
                noHeader={true}
                defaultSortField={"positionSortOrder"}
                fixedHeader={true}
                pointerOnHover={true}
                onRowClicked={(row) => selectPlayer(row.playerId)}
              />

              <Modal isOpen={modalOpen} toggle={toggleModal}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLiveLabel">
                    {selectedPlayer.playerName}
                  </h5>
                </div>
                <div className="modal-body">
                  <Container>
                    <Row>
                      <Col>
                        <h6 className="card-category">
                          FPROS BEST:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.best}
                        </h6>
                      </Col>
                      <Col>
                        <h6 className="card-category">
                          NFL RANK: {selectedPlayer.nflRank}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6 className="card-category">
                          FPROS AVG:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.avg}
                        </h6>
                      </Col>
                      <Col>
                        <h6 className="card-category">
                          AGE:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.age}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6 className="card-category">
                          FPROS WORST:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.worst}
                        </h6>
                      </Col>
                      <Col>
                        <h6 className="card-category">
                          BYE WEEK:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.bye}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h6 className="card-category">
                          FPROS STD DEV:{" "}
                          {!selectedPlayer.fantasyProsInfo
                            ? "N/A"
                            : selectedPlayer.fantasyProsInfo.stdDev}
                        </h6>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="modal-footer">
                  <Button
                    className="btn-link"
                    color="primary"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    Close
                  </Button>
                </div>
              </Modal>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
