import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import SleeperPageNavbar from "./SleeperPageNavbar.js";
import SleeperPageNavLinks from "./SleeperPageNavLinks.js";
import DataTable from "react-data-table-component";
import { Container, Row, Col, Button } from "reactstrap";
import { client } from "../../../services/FantasyNflClient.js";
import Spinner from "../../Common/Spinner.js";

export function SleeperPointsByYear() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let { leagueId, year } = useParams();

  useEffect(() => {
    client
      .sleeperPointsByYear(leagueId, year)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [leagueId, year]);

  const columns = useMemo(
    () => [
      {
        name: "Team Name",
        selector: "teamName",
        sortable: true,
        format: (row) => {
          //eslint-disable-next-line no-useless-concat
          return (
            <Link
              to={{
                pathname:
                  "/sleeper/history/" +
                  `${leagueId}` +
                  "/pointsbyyear/" +
                  `${year}` +
                  `/${row.teamId}`,
                state: { data: row },
              }}
            >
              {" "}
              {row.teamName}
            </Link>
          );
        },
      },
      { name: "Owner", selector: "ownerName", sortable: true },
      {
        name: "Team Score",
        selector: "teamScoreTotal",
        sortable: true,
      },
      {
        name: "Bench Score",
        selector: "benchScoreTotal",
        sortable: true,
      },
      { name: "Res Score", selector: "resScoreTotal", sortable: true },
      {
        name: "Total Score",
        selector: "teamScoreIncBenchAndRes",
        sortable: true,
      },
    ],
    []
  );

  const convertArrayOfObjectsToCSV = (array) => {
    let result = [];

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data.teams[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  const downloadCSV = (array) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
    const filename =
      year + "_TeamScores_" + data.leagueName.replace(/\s+/g, "") + ".csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  return (
    <>
      <SleeperPageNavbar leagueId={leagueId} />
      {loading && <Spinner />}
      {!loading && (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <div className="info">
                <div className="description">
                  <h3 className="info-title">{data.leagueName}</h3>
                  <p className="mb-0">{year} Team Scores</p>
                </div>
              </div>
              <SleeperPageNavLinks leagueId={leagueId} />
            </Col>
          </Row>

          <Row>
            <DataTable
              columns={columns}
              dense={true}
              data={data.teams}
              striped={true}
              noHeader={false}
              defaultSortField={"teamScoreIncBenchAndRes"}
              fixedHeader={true}
              pointerOnHover={true}
            />
          </Row>
          <Row>
            <div className="col text-right mt-2 mb-2">
              <Button
                size="sm"
                color="secondary"
                type="button"
                onClick={() => downloadCSV(data.teams)}
              >
                Export
              </Button>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}
