import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../../../services/FantasyNflClient";

export function SleeperTeamProfileCard() {
  const [team, setTeam] = useState({});
  let { leagueId, teamId } = useParams();

  useEffect(() => {
    client
      .getSleeperTeamProfile(leagueId, teamId)
      .then((data) => {
        setTeam(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [teamId]);

  return (
    <div className="card card-profile card-plain">
      <div className="card-avatar border-white">
        <a>
          <img height={40} width={40} src={team.avatar} alt="..." />
        </a>
      </div>
      <div className="card-body">
        <h4 className="card-title">{team.teamName}</h4>
        <h6 className="card-category">OWNER: {team.ownerName}</h6>
        <h6 className="card-category">DIVISION: {team.division}</h6>
      </div>
    </div>
  );
}
