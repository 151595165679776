import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";

import { Col, Card, CardBody, CardTitle } from "reactstrap";

export function SleeperDivision({ leagueId, divisionName, teams }) {
  const history = useHistory();

  const redirect = (nflTeamId) => {
    history.push(`/sleeper/league/${leagueId}/team/${nflTeamId}`);
  };

  const columns = useMemo(
    () => [
      {
        name: "",
        selector: "avatar",
        sortable: false,
        format: (row) => {
          //eslint-disable-next-line no-useless-concat
          return (
            <div className="card-avatar border-white">
              <a>
                <img height={25} width={25} src={row.avatar} alt="..." />
              </a>
            </div>
          );
        },
      },
      {
        name: "Team",
        selector: "teamName",
        sortable: false,
        width: "250px",
      },
      { name: "W", selector: "wins", sortable: true },
      { name: "L", selector: "losses", sortable: true },
      { name: "T", selector: "draws", sortable: true },
      { name: "Pct", selector: "percentage", sortable: true },
      { name: "F", selector: "pointsFor", sortable: true },
      { name: "A", selector: "pointsAgainst", sortable: true },
    ],
    []
  );

  return (
    <>
      <Col md="12">
        <Card className="card-product card-plain">
          <div className="card-image">
            <CardBody>
              <div className="card-description">
                <CardTitle tag="h5">{divisionName}</CardTitle>
              </div>
              <DataTable
                columns={columns}
                dense={true}
                data={teams}
                striped={true}
                noHeader={true}
                defaultSortAsc={true}
                defaultSortField={"divisionPosition"}
                fixedHeader={true}
                pointerOnHover={true}
                onRowClicked={(row) => redirect(row.id)}
              />
            </CardBody>
          </div>
        </Card>
      </Col>
    </>
  );
}
