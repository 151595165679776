import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { client } from "../../services/FantasyNflClient";

export interface IAdminProps extends RouteComponentProps<{}> {}

export interface IAdminState {
  isLoading: boolean;
  selectedLeagueId: number;
}

export class Admin extends React.Component<IAdminProps, IAdminState> {
  constructor(props: IAdminProps, state: IAdminState) {
    super(props, state);
    this.state = {
      isLoading: false,
      selectedLeagueId: 0,
    };
  }

  async populateRookies() {
    await client
      .populateRookies()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async populateCache() {
    await client
      .populateCache()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async populateFullLeagueYearData() {
    await client
      .populateFullLeagueYearData()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async refreshKeepers() {
    await client
      .refreshKeepers(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async refreshRosters() {
    await client
      .refreshRosters(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async refreshStandings() {
    await client
      .refreshStandings(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async populateLastSeasonsRoster() {
    await client
      .populateLastSeasonsRoster(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async getNflPlayerRankings() {
    await client
      .getNflPlayerRankings()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async setFantasyProsPlayerIds() {
    await client
      .setFantasyProsPlayerIds()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async loadNewFantasyProsFile() {
    await client
      .loadNewFantasyProsFile()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async populateLeagueDraftOrder() {
    await client
      .populateLeagueDraftOrder(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async updateLeagueYearData() {
    await client
      .updateLeagueYearData()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async updateHistoricalTeamWeekData() {
    await client
      .updateHistoricalTeamWeekData(this.state.selectedLeagueId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async updateSleeperLeagues() {
    await client
      .sleeperUpdateLeagues()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async updateSleeperTeamWeekData() {
    await client
      .sleeperHistoricalTeamWeekData()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  async updateSleeperPlayers() {
    await client
      .sleeperUpdatePlayers()
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    return (
      <div className="blog-2 section">
        <div className="container">
          <div className="row">
            <div className="col">
              <select
                className="form-control w-25"
                value={this.state.selectedLeagueId}
                onChange={(e: any) => {
                  this.setState({
                    selectedLeagueId: parseInt(e.target.value),
                  });
                }}
              >
                {[
                  { id: 0, description: "All" },
                  { id: 132, description: "Global League" },
                  { id: 348764, description: "Col League" },
                  { id: 612374, description: "Bangin Heads League" },
                  { id: 8645669, description: "Champions League" },
                  { id: 4800184, description: "Universal" },
                  { id: 6199037, description: "Premier" },
                  { id: 2298387, description: "Dynasty" },
                ].map((x, i) => (
                  <option key={`refreshKeepers_` + i} value={x.id}>
                    {x.description}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <Spinner
                hidden={!this.state.isLoading}
                animation="border"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.populateRookies()}
              >
                Populate Rookies
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.populateCache()}
              >
                Populate Cache
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.updateHistoricalTeamWeekData()}
              >
                Update Historical Team Week Data
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.updateLeagueYearData()}
              >
                Update League Year Data
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.populateFullLeagueYearData()}
              >
                Populate Full League Year Data
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.populateLastSeasonsRoster()}
              >
                Populate LastSeasonsRoster
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.refreshKeepers()}
              >
                Refresh Keepers
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.refreshRosters()}
              >
                Refresh Rosters
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.refreshStandings()}
              >
                Refresh Standings
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.getNflPlayerRankings()}
              >
                Get NFL Rankings
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.setFantasyProsPlayerIds()}
              >
                Set Fantasy Pros Ids
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.loadNewFantasyProsFile()}
              >
                Load New FPros File
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.populateLeagueDraftOrder()}
              >
                Build Draft Order
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.updateSleeperLeagues()}
              >
                Sleeper League Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.updateSleeperPlayers()}
              >
                Sleeper Players Update
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                color="danger"
                type="button"
                onClick={() => this.updateSleeperTeamWeekData()}
              >
                Sleeper Team Week Data
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
